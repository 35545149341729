export const state = () => ({
  result: {},
  order: {},
})

export const mutations = {
  setResult(state, data) {
    state.result = data
  },
  setOrder(state, data) {
    state.order = data
  },
}

export const actions = {

}

export const getters = {
  order(state) {
    return state.order
  },
  result(state) {
    return state.result
  },
}
